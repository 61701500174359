import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import brand from '../../images/brand.svg';
import hamburger from '../../images/hamburger.svg';
import close from '../../images/close.svg';
import SecondaryLink from '../Buttons/SecondaryLink';
import PrimaryLink from '../Buttons/PrimaryLink';
import LanguageSelector from '../LanguageSelector';
import { Link } from 'gatsby';

const Header = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const [urlWhite, setUrlWhite] = useState('');

  const handleSwitchWhitePaper = (locale) =>{
    switch (locale){
      case 'en':
        return 'https://register.preprod.agrotoken.io/bundles/app/whitepaper_en.pdf'
      case 'es':
        return 'https://register.preprod.agrotoken.io/bundles/app/whitepaper_es.pdf'
      case 'pt':
        return 'https://register.preprod.agrotoken.io/bundles/app/whitepaper_pt.pdf'
      default:
        return 'https://register.preprod.agrotoken.io/bundles/app/whitepaper_en.pdf'
    }
  }
  useEffect(()=> {

    setUrlWhite(handleSwitchWhitePaper(intl.locale))

  }, [intl.locale])

  const links = [
    { text: 'home', isExternal: false },
    { text: 'we-do', isExternal: false },
    { text: 'use-cases', isExternal: false },
    { text: 'be-part', isExternal: false },
    { text: 'whitepaper', isExternal: true },
    { text: 'help', isExternal: true },
  ];
  return (
    <header
      className={` ${
        open ? 'h-screen' : 'h-16'
      } z-50 lg:h-20 p-4 transition-all duration-400 ease-in-out fixed overflow-hidden lg:overflow-visible lg:static top-0 w-full bg-white lg:py-6 lg:px-8 flex lg:items-center lg:justify-between border-b border-gray-lighter flex-col lg:flex-row`}
    >
      <div className="flex flex-col lg:flex-row">
        <div className="mr-10 mb-10 lg:mb-0">
          <Link to={intl.formatMessage({ id: 'links.home.url' })}>
            <img src={brand} alt="agrotoken" className="relative top-1" />
          </Link>
          <button
            type="button"
            onClick={() => setOpen(!open)}
            className="border-none bg-white lg:hidden absolute top-6 right-5"
          >
            {open ? (
              <img src={close} alt="menu" />
            ) : (
              <img src={hamburger} alt="menu" />
            )}
          </button>
        </div>
        <ul className="flex flex-col space-y-8 lg:flex-row lg:space-y-0 mt-2.5">
          {links.map((item) => {
            if (item.text === 'whitepaper'){

              return (
                <li key={item.text}>
                  <SecondaryLink
                    text={intl.formatMessage({ id: `links.${item.text}.text` })}
                    to={urlWhite}
                    isisExternal={false}
                    onClick={() => setOpen(false)}
                  />
                </li>)
            }else {
              return (
                <li key={item.text}>
                  <SecondaryLink
                    text={intl.formatMessage({ id: `links.${item.text}.text` })}
                    to={intl.formatMessage({ id: `links.${item.text}.url` })}
                    isisExternal={false}
                    onClick={() => setOpen(false)}
                  />
                </li>)
            }
          })}
        </ul>
      </div>

      <div className="mt-8 lg:mt-0 flex lg:items-center flex-col-reverse space-y-reverse space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row">
        <SecondaryLink
          className="w-full"
          bordered
          text={intl.formatMessage({ id: 'login' })}
          to={intl.formatMessage({ id: 'loginUrl' })}
          isisExternal={true}
        />
        <SecondaryLink
          className="hidden lg:block"
          text={intl.formatMessage({ id: 'signup' })}
          to={intl.formatMessage({ id: 'signupUrl' })}
          isisExternal={false}
        />
        <PrimaryLink
          className="lg:hidden w-full"
          text={intl.formatMessage({ id: 'signup' })}
          to={intl.formatMessage({ id: 'signupUrl' })}
        />
        <LanguageSelector />
      </div>
    </header>
  );
};
export default Header;
